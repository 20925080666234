<template>
	<div class="bgpage" style="padding-top: 50px; min-height: 100vh;">
		<div id="app-container">

			<div>

				<div id="logo-holder">
					<a href="https://audology.app">
						<img src="@/assets/audology-rev.svg" alt="Logo" />
					</a>
				</div>
				<div id="form-wrapper">

					<div v-if="!showingManualLoginForm && !showingManualEmailCodeForm" class="login-selection-buttons">

						<div class="instructions">
							<p>
								If you or your station/network/agency has an Audology account, use your work email account to login.
								Select the appropriate Microsoft (Office/Outlook) or Google (GMail) option for your account.
								<br/><br/>
								<span>
									We are aware of Microsoft SSO security policies causing problems for some users.
									If you are unable to use MS SSO, please use the "Email me a login link" option below for your work email address.
								</span>
							</p>
						</div>

						<div class="ms-login-btn" v-on:click="doMSAdLogin">
							<div><img src="@/assets/microsoft-logo.png" /></div>
							<div><span>Sign in with Microsoft account</span></div>
						</div>

						<GoogleLogin :callback="finGoogleLogin"></GoogleLogin>

						<div v-if="store.isDevLocal || allowManualLoginOverride" class="ms-login-btn" v-on:click="showingManualLoginForm = true" style="margin-top: 15px;">
							<div><i class="mdi mdi-at"></i></div>
							<div><span>Manually sign in with Email</span></div>
						</div>

						<div class="ms-login-btn" v-on:click="showingManualEmailCodeForm = true" style="margin-top: 15px;">
							<div><i class="mdi mdi-at"></i></div>
							<div><span>Email me a login link</span></div>
						</div>

					</div>

					<div v-if="showingManualLoginForm">
						<div>
							<span @click="showingManualLoginForm = false" class="login-back-btn">
								<i class="mdi mdi-chevron-left"></i>
								Back
							</span>
						</div>
						<div class="form-input-group">
							<input type='email' v-model="email" placeholder="Email Address" />
							<div class="form-input-fail" :class="{shown : emailState === false}">Please enter a valid email address.</div>
						</div>
						<div class="form-input-group" style="margin-bottom: 0px;">
							<input type='password' v-model="pass" placeholder="Password/Token" />
						</div>

						<br/><br/>
						<button class="login-button" v-on:click="submitForm" v-bind:class="{'button-is-working':(form1working === true)}">
							<span>Submit</span>
							<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
						</button>
					</div>

					<div v-if="showingManualEmailCodeForm && showEmailLinkSentSuccess === false">
						<div>
							<span @click="showingManualEmailCodeForm = false" class="login-back-btn">
								<i class="mdi mdi-chevron-left"></i>
								Back
							</span>
							<p style="margin: 15px 0 20px;">
								Use your work email address, we will send you a temporary link to login with if the address exists in our system or you have an email account with a whitelisted domain.
							</p>
						</div>
						<div class="form-input-group">
							<input type='email' v-model="email" placeholder="Email Address" />
							<div class="form-input-fail" :class="{shown : emailState === false}">Please enter a valid email address.</div>
						</div>

						<br/>
						<button class="login-button" v-on:click="submitFormEmailCode" v-bind:class="{'button-is-working':(form1working === true)}">
							<span>Submit</span>
							<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
						</button>
					</div>

					<div v-if="showEmailLinkSentSuccess">
						<div class="instructions">
							<p>
								An email has been sent to your address with a temporary login link.
								<br/><br/>
								Please check your email and click the link to login.
							</p>
						</div>
					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import * as msal from "@azure/msal-browser"; // MS login

export default {
	name: 'LoginPage',
	components: {
		WorkingButtonInsert
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			headerNotes: "",
			requestedLink: "", //for use after successful login
			userIp: null,

			email: '',
			pass: '', //manual login from form on this page
			emailtoken: '', //token from temporary email login link

			checksTriggered: false,
			form1working: false,

			hasLoggedOut: false,

			doingMSLogin: false,
			doingGoogleLogin: false,
			accessToken: null,
			adEmail: null,
			showingManualLoginForm: false,
			allowManualLoginOverride: false,
			showingManualEmailCodeForm: false,

			showEmailLinkSentSuccess: false,
		}
	},
	computed: {
		emailState() {
			if(this.checksTriggered === false && this.email.length >= 0 && /.+@.+\..+/.test(this.email) === false) {
				return null;
			}
			else if(this.email.length >= 0 && /.+@.+\..+/.test(this.email)) {
				return true;
			}
			return false;
		},
	},
	methods: {
		doMSAdLogin() {
			if(!this.doingMSLogin) {
				let msalConfig = {
					auth: {
						clientId: '4d63896c-45e9-4829-99fa-9802c806648b',
						authority: "https://login.microsoftonline.com/common/",
						redirectUri: this.store.urlroot+'/sso-callback-ms.php',
					}
				};
				this.doingMSLogin = true;
				let self = this;
				const msalInstance = new msal.PublicClientApplication(msalConfig);
				msalInstance.acquireTokenPopup({
					scopes: ["User.Read"] // shorthand for https://graph.microsoft.com/User.Read
				}).then((response) => {
					if(response.accessToken) {
						self.accessToken = response.accessToken;
					}
					if(response.account && response.account.username) {
						self.adEmail = response.account.username;
					}
					self.finishMSLogin()
				}).catch((error) => {
					console.log(error); // handle errors
				});
			}
		},
		finishMSLogin() { //check from our server via MS Graph that person is who they say they are
			if(this.doingMSLogin === true && this.accessToken && this.adEmail) {
				let self = this
				axios.post(
					"/post/fin-microsoft-login.php",
					JSON.stringify({
						accessToken: this.accessToken,
						email: this.adEmail
					})
				).then(function(response) {
					let ret = response.data //response.data is returned info
					self.doingMSLogin = false
					if(ret.rruser) { //if this comes back we have logged in successfully
						self.setUser(ret.rruser)
						self.store.usercreds = {id: ret.rruser.id, token: ret.rruser.token}
						window.localStorage.setItem('useremail', ret.rruser.email)
						window.localStorage.setItem('usertoken', ret.rruser.token)
						window.localStorage.setItem('userid', ret.rruser.id)
						self.$router.push("/");
					}
					if(ret.error === 1) {
						self.form1working = false
						self.showKalert(ret.errob, true)
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		finGoogleLogin(response) {
			if(this.doingGoogleLogin === false) {
				this.doingGoogleLogin = true
				let self = this
				axios.post(
					"/post/fin-google-login.php",
					JSON.stringify({
						jwt: response.credential,
					})
				).then(function(response) {
					let ret = response.data //response.data is returned info
					self.doingGoogleLogin = false
					if(ret.rruser) { //if this comes back we have logged in successfully
						self.setUser(ret.rruser)
						self.store.usercreds = {id: ret.rruser.id, token: ret.rruser.token}
						window.localStorage.setItem('useremail', ret.rruser.email)
						window.localStorage.setItem('usertoken', ret.rruser.token)
						window.localStorage.setItem('userid', ret.rruser.id)
						self.$router.push("/");
					}
					if(ret.error === 1) {
						self.form1working = false;
						self.showKalert(ret.errob);
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		submitForm() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				let checkitems = [this.emailState];
				for(let i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				if(this.pass.length < 3 && this.emailtoken.length < 3) return false;
				this.form1working = true;
				let self = this;
				axios.post(
					"/post/do-login.php",
					JSON.stringify({
						email: self.email,
						pass: self.pass,
						emailtoken: self.emailtoken,
					})
				).then(function(response) {
					let ret = response.data; //response.data is returned info
					if(ret.rruser) { //if this comes back we have logged in successfully
						self.setUser(ret.rruser);
						self.store.usercreds = {id: ret.rruser.id, token: ret.rruser.token}
						window.localStorage.setItem('useremail', ret.rruser.email);
						window.localStorage.setItem('usertoken', ret.rruser.token);
						window.localStorage.setItem('userid', ret.rruser.id);
						self.$router.push("/");
					}
					if(ret.error === 1) {
						self.form1working = false;
						self.showKalert(ret.errob);
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		submitFormEmailCode() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true //check for validations
				let checkitems = [this.emailState]
				for(let i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false
				}
				this.form1working = true; //make button working class after pulse effect is finished
				let self = this
				axios.post(
					"/post/email-login-link-send.php",
					JSON.stringify({
						email: self.email,
					})
				).then(function(response) {
					let ret = response.data //response.data is returned info
					self.form1working = false
					if(ret.error === 1) {
						self.showKalert(ret.errob)
					}
					else if(ret.success === 1) {
						self.showKalert(ret.successob)
						self.showEmailLinkSentSuccess = true
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "Audology Login";
		if(this.store.sesscheckcomplete && this.store.user.id > 0) {
			this.$router.push("/");
		}
	},
	created() {
		//GET params are stored in store.getparams from App.vue already but grab the manual logged out one in case
		let urlparams = this.$route.query
		this.hasLoggedOut = false
		if(urlparams.loggedout && parseInt(urlparams.loggedout) === 1) {
			this.hasLoggedOut = true
		}
		if(urlparams.manual && parseInt(urlparams.manual) === 1) {
			this.allowManualLoginOverride = true
		}
		if(urlparams.omnicom_temp && parseInt(urlparams.omnicom_temp) === 1) {
			this.allowManualLoginOverride = true
		}
		if(urlparams.email && urlparams.email.length > 0 && urlparams.emailtoken && urlparams.emailtoken.length > 0) {
			this.email = urlparams.email
			this.emailtoken = urlparams.emailtoken
			this.submitForm() //trigger login with email token
		}
	},
}
</script>

<style scoped>
[v-cloak] {
	display: none;
}
.bgpage {
	background-image: url('~@/assets/login-background.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: var(--bluedark);
}
#app-container {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
#logo-holder {
	text-align: center;
	background-color: var(--bluemain);
	padding: 30px 0;
	border-radius: 10px 10px 0 0;
}
#logo-holder img {
	height: 44px;
	margin-top: 10px;
}
#form-wrapper {
	padding: 40px 60px 60px;
	margin-bottom: 80px;
	border-radius: 0 0 10px 10px;
	box-shadow: 10px 0 40px 6px rgba(0,0,0,.08);
	background: #FFFFFF;
	text-align: left;
}
#form-wrapper input {
	border: 1px solid #BBB;
	width: calc(100% - 2px);
	display: block;
	padding: 10px;
	font-size: 18px;
}
.form-input-fail {
	padding: 5px 0;
	visibility: hidden;
}
.form-input-fail.shown {
	visibility: visible;
}
.login-button {
	margin-bottom: 20px;
	border-radius: 6px;
	background: var(--bluemain);
	color: #fff;
	font-size: 15px;
	border: none;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	font-weight: bold;
	transition: ease-in-out 0.5s;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 17px 0;
	cursor: pointer;
}
.login-button:hover {
	background: var(--bluelight);
}
.text-link {
	cursor: pointer;
	width: fit-content;
	padding-bottom: 1px;
	margin: 0 auto;
	border-bottom: 1px dotted transparent;
	transition: ease-in-out 0.5s;
}
.text-link:hover {
	border-bottom: 1px dotted #666;
}
.login-selection-buttons {
	text-align: center;
}
.ms-login-btn {
	border: 1px solid #dadce0;
	padding: 8px;
	font-size: 12px;
	display: flex;
	align-items: center;
	width: 216px;
	margin: 0 auto 18px;
	border-radius: 4px;
	cursor: pointer;
}
.ms-login-btn img {
	margin-right: 8px;
}
.ms-login-btn:hover {
	border: 1px solid #d2e3fc;
}
.ms-login-btn .mdi-at {
	font-size: 20px;
	color: var(--bluemain);
	margin-right: 8px;
}
.login-back-btn {
	cursor: pointer;
	border: 1px solid #dadce0;
	padding: 8px;
	font-size: 12px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	width: 64px;
	margin-bottom: 10px;
}
.login-back-btn:hover {
	border: 1px solid #d2e3fc;
}
.instructions {
	border: 1px solid var(--greymid);
	background-color: var(--greylight);
	padding: 10px;
	margin-bottom: 20px;
}
</style>
