<template>
	<div id="excel-basic-wrapper">
		<table class="excel-table-info" :class="{shown : isExporting}">
			<tbody>
			<tr>
				<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Demographic</td>
				<td data-f-name="Tahoma" data-f-sz="10">{{demodata.demoName}}</td>
			</tr>
			<tr>
				<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Markets</td>
				<td data-f-name="Tahoma" data-f-sz="10">{{marketListText}}</td>
			</tr>
			</tbody>
		</table>
		<BlankExcelTwoRows></BlankExcelTwoRows>
		<div class="table-holder">
			<table v-if="demodata && demodata.summary" class="std-tbl">
				<tbody>
					<tr v-for="(trow, ind) in tableContent" :key="ind" :class="{thead : trow.type === 'header'}">
						<td v-for="(cellvalue, cind) in trow.cellValues" :key="cind" :data-t="getCellMeta('dtype',trow.type,cind)" :data-f-bold="getCellMeta('bold',trow.type)" :data-fill-color="getCellMeta('fillcolor',trow.type)" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{cellvalue}}</td>
					</tr>
					<tr v-if="showWeekWarning" class="thead">
						<td colspan="13"></td>
					</tr>
					<tr v-if="showWeekWarning" class="thead">
						<td colspan="13" style="font-weight: normal;" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">
							* Please note that the sum of individual week splits may show slightly different results to the overall total due to rounding.
						</td>
					</tr>
				</tbody>
			</table>
			<BlankExcelTwoRows></BlankExcelTwoRows>
			<table class="excel-table-info" :class="{shown : isExporting}">
				<tbody>
				<tr>
					<td data-f-name="Tahoma" data-f-sz="10">Source: {{store.returnedDataSourcing}}</td>
				</tr>
				</tbody>
			</table>
		</div>

		<div class="choices-holder" :class="{shown : showtablelayouteditor}">
			<p class="bold" style="display: flex; justify-content: space-between;">
				Row Groups
				<i class="mdi mdi-close" @click="$emit('close')"></i>
			</p>
			<div v-for="rg in rowGroups" :key="rg.name" class="choice-div">
				<label>
					<input type="checkbox" v-model="rg.enabled" />
					{{rg.name}}
				</label>
				<div class="choice-movers">
					<i class="mdi mdi-arrow-down-thick" @click="moveChoiceDown('rowgroups', rg)"></i>
					<i class="mdi mdi-arrow-up-thick" @click="moveChoiceUp('rowgroups', rg)"></i>
				</div>
			</div>
			<p class="bold" style="margin-top: 10px;">Columns</p>
			<div v-for="c in columns" :key="c.name" class="choice-div">
				<label>
					<input type="checkbox" v-model="c.enabled" />
					{{c.name}}
				</label>
				<div class="choice-movers">
					<i class="mdi mdi-arrow-down-thick" @click="moveChoiceDown('columns', c)"></i>
					<i class="mdi mdi-arrow-up-thick" @click="moveChoiceUp('columns', c)"></i>
				</div>
			</div>
			<div>
				<span class="tbl-btn" @click="saveTableLayout()">Save</span>
				<span class="tbl-btn" @click="getTableLayoutTemplates()">Load</span>
			</div>
		</div>

		<div class="primary-button" @click="exportToExcel()" style="margin-top: 20px;">
			<i class="mdi mdi-file-excel-outline"></i>
			Export To Excel
		</div>

		<div v-if="showingLayoutChooserOverlay" class="loader-overlay">
			<div class="popup-container">
				<div class="popup-topbar">
					<span class="bold">R&F Table Layout Templates</span>
					<span class="closer" @click="showingLayoutChooserOverlay = false">
					<i class="mdi mdi-close"></i>
				</span>
				</div>
				<div class="popup-insides">
					<div>
						<p v-for="fl in fetchedLayouts" :key="fl.id" class="layout-item" @click="loadTableLayout(fl)">{{fl.name}}</p>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows.vue";
import TableToExcel from "@linways/table-to-excel";
import axios from "axios";

export default {
	name: "RFResultSummaryTable",
	components: {
		BlankExcelTwoRows
	},
	props: {
		demodata: {
			type: Object,
		},
		showactualweeks: {
			type: String,
			default: 'generic'
		},
		showtablelayouteditor: {
			type: Boolean,
			default: false
		}
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			isExporting: false,
			activeTableLayoutTemplateId: 0,
			showingLayoutChooserOverlay: false,
			fetchedLayouts: [],
		}
	},
	computed: {
		rowGroups: {
			get() {
				return this.store.viewPreferences.rfSummaryTableRowGroups
			},
			set(value) {
				this.store.viewPreferences.rfSummaryTableRowGroups = value
			}
		},
		columns: {
			get() {
				return this.store.viewPreferences.rfSummaryTableColumns
			},
			set(value) {
				this.store.viewPreferences.rfSummaryTableColumns = value
			}
		},
		tableContent() {
			let tc = []
			for(let rg of this.rowGroups) {
				if(rg.enabled) {
					let rowheadings = this.getHeadingColumns(rg.name)
					tc.push({type: 'header', cellValues: rowheadings})
					let datasource = null
					if(rg.name === 'Markets') datasource = this.demodata.marketSplits
					else if(rg.name === 'Networks') datasource = this.comboRows
					else if(rg.name === 'Stations') datasource = this.demodata.stationResults
					else if(rg.name === 'Week (Cume)') datasource = this.demodata.weekSplits
					else if(rg.name === 'Week Splits') datasource = this.demodata.weekSplits
					else if(rg.name === 'Total') datasource = [this.demodata.summary]
					else if(rg.name === 'Networks x Market') datasource = this.comboMarketRows

					if(datasource) {
						for(let mk of datasource) {
							let rowdata = []
							if(rg.name === 'Markets') rowdata.push(mk.marketName)
							else if(rg.name === 'Networks') rowdata.push(mk.name)
							else if(rg.name === 'Stations') rowdata.push(this.getStationNameFromId(mk.stationId, true))
							else if(rg.name === 'Week (Cume)') rowdata.push(this.getDisplayWeek(mk, 'cumulative'))
							else if(rg.name === 'Week Splits') rowdata.push(this.getDisplayWeek(mk))
							else if(rg.name === 'Total') rowdata.push('Total')
							else if(rg.name === 'Networks x Market') rowdata.push(mk.name)
							for(let c of this.columns) {
								if(c.enabled) {
									let val = ''
									if(c.name === 'Spot Count') {
										if(rg.name === 'Week (Cume)') val = this.numdisplay(mk.nSpotsCumulative)
										else val = this.numdisplay(mk.nSpots)
									}
									else if(c.name === 'Sample Size') val = this.numdisplay(mk.sampleSize)
									else if(c.name === 'Universe Est') val = this.numdisplay(mk.universeEstimate)
									else if(c.name === '1+ Reach') {
										if(rg.name === 'Week Splits') {
											if(mk.nPlusExposureThisWeek) val = this.numdisplay(this.getItemProperty(mk.nPlusExposureThisWeek.find(item => item.spot === 1), 'nPlusReach'))
										}
										else {
											if(mk.nPlusExposure) val = this.numdisplay(this.getItemProperty(mk.nPlusExposure.find(item => item.spot === 1), 'nPlusReach'))
										}
									}
									else if(c.name === 'Avg Frequency') {
										if(rg.name === 'Week (Cume)') val = mk.avgFrequencyCumulative
										else val = mk.avgFrequency
									}
									else if(c.name === '1+ %') {
										if (rg.name === 'Week Splits') {
											if(mk.nPlusExposureThisWeek) val = this.numdisplayNdp(this.getItemProperty(mk.nPlusExposureThisWeek.find(item => item.spot === 1), 'nPlusPct'), 2)
										}
										else {
											if(mk.nPlusExposure) val = this.numdisplayNdp(this.getItemProperty(mk.nPlusExposure.find(item => item.spot === 1), 'nPlusPct'), 2)
										}
									}
									else if(c.name === '2+'){
										if(rg.name === 'Week Splits') {
											if(mk.nPlusExposureThisWeek) val = this.numdisplay(this.getItemProperty(mk.nPlusExposureThisWeek.find(item => item.spot === 2), 'nPlusReach'))
										}
										else {
											if(mk.nPlusExposure) val = this.numdisplay(this.getItemProperty(mk.nPlusExposure.find(item => item.spot === 2), 'nPlusReach'))
										}
									}
									else if(c.name === '2+ %') {
										if(rg.name === 'Week Splits') {
											if(mk.nPlusExposureThisWeek) val = this.numdisplayNdp(this.getItemProperty(mk.nPlusExposureThisWeek.find(item => item.spot === 2), 'nPlusPct'), 2)
										}
										else {
											if(mk.nPlusExposure) val = this.numdisplayNdp(this.getItemProperty(mk.nPlusExposure.find(item => item.spot === 2), 'nPlusPct'),2)
										}
									}
									else if(c.name === '3+') {
										if(rg.name === 'Week Splits') {
											if(mk.nPlusExposureThisWeek) val = this.numdisplay(this.getItemProperty(mk.nPlusExposureThisWeek.find(item => item.spot === 3), 'nPlusReach'))
										}
										else {
											if(mk.nPlusExposure) val = this.numdisplay(this.getItemProperty(mk.nPlusExposure.find(item => item.spot === 3), 'nPlusReach'))
										}
									}
									else if(c.name === '3+ %') {
										if(rg.name === 'Week Splits') {
											if(mk.nPlusExposureThisWeek) val = this.numdisplayNdp(this.getItemProperty(mk.nPlusExposureThisWeek.find(item => item.spot === 3), 'nPlusPct'), 2)
										}
										else {
											if(mk.nPlusExposure) val = this.numdisplayNdp(this.getItemProperty(mk.nPlusExposure.find(item => item.spot === 3), 'nPlusPct'),2)
										}
									}
									else if(c.name === 'Gross Impacts') {
										if(rg.name === 'Week (Cume)') val = this.numdisplay(mk.impactsCumulative)
										else val = this.numdisplay(mk.impacts)
									}
									else if(c.name === 'Avg Aud') {
										if(rg.name === 'Week (Cume)') val = this.numdisplay(mk.avgAudCumulative)
										else val = this.numdisplay(mk.avgAud)
									}
									else if(c.name === 'Cost') {
										if(rg.name === 'Week (Cume)') val = this.numdisplay(mk.costCumulative)
										else val = this.numdisplay(mk.cost)
									}
									else if(c.name === 'CPM') {
										if(rg.name === 'Week (Cume)') val = this.numdisplayNdp(mk.cpmCumulative,2)
										else val = this.numdisplayNdp(mk.cpm,2)
									}
									rowdata.push(val)
								}
							}
							tc.push({type: 'data', cellValues: rowdata})
						}
					}
				}
			}
			return tc
		},
		marketListText() {
			let ms = []
			for(let mk of this.demodata.marketSplits) {
				ms.push(mk.marketName)
			}
			return ms.join(', ')
		},
		firstWeekDisplayName() {
			if(this.showactualweeks === 'actual') return this.demodata.weekSplits[0].actualWeekId
			else if(this.showactualweeks === 'wcdate') return this.demodata.weekSplits[0].actualWeekStartDate
			else if(this.showactualweeks === 'wcdatedmy') return this.convertYMDtoDMY(this.demodata.weekSplits[0].actualWeekStartDate)
			return 'W'+this.demodata.weekSplits[0].weekNumber
		},
		comboRows() {
			let rowdata = []
			for(let cid in this.demodata.combos) {
				let rt = this.demodata.combos[cid]
				let r = rt.summary
				r.id = cid
				let cob = this.store.stationcombos.find(c => c.id === r.id)
				if(cob) {
					r.name = cob.name
					rowdata.push(r)
				}
			}
			return rowdata
		},
		comboMarketRows() {
			let rowdata = []
			// let metroSubSplits = [2453,2454,2455,2456,2457,2458]
			for(let mk of this.demodata.marketSplits) {
				let mid = mk.marketId
				for(let cid in this.demodata.combos) {
					// if(metroSubSplits.includes('c_'+cid) && !this.store.gfkMetroMktIds.includes(mid)) continue
					let rt = this.demodata.combos[cid]
					let resob = rt.marketSplits.find(item => item.marketId === mid)
					if(resob) {
						resob.id = mid+'xx'+cid
						let cob = this.store.stationcombos.find(c => c.id === cid)
						if(cob) {
							resob.name = mk.marketName + ' x ' + cob.name
							rowdata.push(resob)
						}
					}
				}
			}
			return rowdata
		},
		showWeekWarning() {
			let show = false
			if(this.rowGroups.find(item => item.name === 'Week Splits').enabled) {
				show = true
			}
			else if(this.rowGroups.find(item => item.name === 'Week (Cume)').enabled) {
				show = true
			}
			return show
		},
	},
	methods: {
		saveTableLayout() {
			if(this.activeTableLayoutTemplateId > 0) {
				this.saveTableLayoutToDatabase()
			}
			else {
				let c = window.prompt('Please enter a name for this table layout template')
				if(c && c.length > 0) {
					this.saveTableLayoutToDatabase(c)
				}
			}
		},
		saveTableLayoutToDatabase(newname = null) {
			let self = this
			axios.post(
				"/post/rftable-layout-save.php",
				JSON.stringify({
					usercreds: self.store.usercreds,
					layoutname: newname,
					id: this.activeTableLayoutTemplateId,
					jsob: {
						rowgroups: this.rowGroups,
						columns: this.columns
					}
				})
			).then(function (response) {
				let ret = response.data
				if (ret.layoutId) self.activeLayoutTemplateId = ret.layoutId
			}).catch(function (error) {
				console.log(error)
				self.store.showLoader = false
			});
		},
		getTableLayoutTemplates() {
			let self = this
			axios.post(
				"/post/rftable-layout-list-load.php",
				JSON.stringify({
					usercreds: self.store.usercreds,
				})
			).then(function (response) {
				let ret = response.data
				if (ret.fetchedLayouts) self.fetchedLayouts = ret.fetchedLayouts
				self.showingLayoutChooserOverlay = true
			}).catch(function (error) {
				console.log(error)
				self.store.showLoader = false
			});
		},
		loadTableLayout(fl) {
			this.activeTableLayoutTemplateId = fl.id
			this.rowGroups = fl.jsob.rowgroups
			this.columns = fl.jsob.columns
			this.showingLayoutChooserOverlay = false
		},
		getCellMeta(key, rowtype, colind = null) {
			if(rowtype === 'header') { //header rows
				if(key === 'bold') return 'true'
				else if(key === 'fillcolor') return 'FFEAEAEA'
				else if(key === 'dtype') return 's'
			}
			else { //data rows
				if(key === 'bold') return 'false'
				else if(key === 'fillcolor') return 'FFFFFFFF'
				else if(key === 'dtype') {
					if(colind === 0) return 's'
					return 'n'
				}
			}
		},
		getHeadingColumns(rowgroup) {
			let cols = []
			if(rowgroup === 'Markets') cols.push('Market')
			else if(rowgroup === 'Networks') cols.push('Network')
			else if(rowgroup === 'Stations') cols.push('Station')
			else if(rowgroup === 'Week (Cume)') cols.push('Week (Cume)')
			else if(rowgroup === 'Week Splits') cols.push('Week')
			else if(rowgroup === 'Total') cols.push('Total')
			else if(rowgroup === 'Networks x Market') cols.push('Market/Network')
			for(let c of this.columns) {
				if(c.enabled) cols.push(c.name)
			}
			return cols
		},
		moveChoiceUp(section, myitem) {
			if(section === 'rowgroups') {
				let itemind = this.rowGroups.findIndex(item => item.name === myitem.name)
				if(itemind !== false && itemind > 0) {
					let temp = this.rowGroups[itemind]
					this.rowGroups[itemind] = this.rowGroups[itemind - 1]
					this.rowGroups[itemind - 1] = temp
				}
			}
			else if(section === 'columns') {
				let itemind = this.columns.findIndex(item => item.name === myitem.name)
				if(itemind !== false && itemind > 0) {
					let temp = this.columns[itemind]
					this.columns[itemind] = this.columns[itemind - 1]
					this.columns[itemind - 1] = temp
				}
			}
		},
		moveChoiceDown(section, myitem) {
			if(section === 'rowgroups') {
				let itemind = this.rowGroups.findIndex(item => item.name === myitem.name)
				if(itemind !== false && itemind < this.rowGroups.length - 1) {
					let temp = this.rowGroups[itemind]
					this.rowGroups[itemind] = this.rowGroups[itemind + 1]
					this.rowGroups[itemind + 1] = temp
				}
			}
			else if(section === 'columns') {
				let itemind = this.columns.findIndex(item => item.name === myitem.name)
				if(itemind !== false && itemind < this.columns.length - 1) {
					let temp = this.columns[itemind]
					this.columns[itemind] = this.columns[itemind + 1]
					this.columns[itemind + 1] = temp
				}
			}
		},
		getDisplayWeek(mk, type = 'singleweek') {
			let out = ''
			if(type === 'cumulative') out = this.firstWeekDisplayName + ' to '
			if (this.showactualweeks === 'actual') return out + mk.actualWeekId
			else if (this.showactualweeks === 'wcdate') {
				if(type === 'cumulative') return out + mk.actualWeekEndDate
				else return mk.actualWeekStartDate
			}
			else if (this.showactualweeks === 'wcdatedmy') {
				if(type === 'cumulative') return out + this.convertYMDtoDMY(mk.actualWeekEndDate)
				else return this.convertYMDtoDMY(mk.actualWeekStartDate)
			}
			return out + 'W' + mk.weekNumber
		},
		getItemProperty(item, propname) {
			if(!item) return null
			return item[propname]
		},
		exportToExcel() {
			this.excelbook = null;
			this.isExporting = true
			let content = document.querySelector("#excel-basic-wrapper")
			let contentT = content.cloneNode(true)
			contentT.innerHTML = contentT.innerHTML.replace(/,/g,''); //replaces , globally
			TableToExcel.convert(contentT, {
				name: "Audology RF Summary.xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
			let self = this
			setTimeout(function() {
				self.isExporting = false
			}, 1000)
		},
	},
	watch: {
		'store.rfSummaryTableTriggerExcelExport': function() {
			if(this.store.rfSummaryTableTriggerExcelExport === true) {
				this.exportToExcel()
			}
		}
	},
	mounted() {

	}
}
</script>

<style scoped>
.excel-table-info {
	display: none;
}
.excel-table-info.shown {
	display: table;
}
.table-holder {
	width: 100%;
	overflow-x: auto;
}
.std-tbl tbody tr.thead tr {
	background-color: var(--greylight) !important;
}
.thead td {
	padding: 7px 10px;
}
.choices-holder {
	display: none;
	position: fixed;
	right: 50px;
	top: 100px;
	border: 1px solid var(--greymid);
	background-color: #F9F9F9;
	padding: 10px;
	box-shadow: 0 0 20px 5px rgba(0,0,0,0.1);
}
.choices-holder.shown {
	display: block;
}
.choices-holder p {
	font-size: 13px;
	margin-bottom: 10px;
}
.choice-div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
	gap: 20px;
	font-size: 12px;
}
.choice-div label {
	display: flex;
	align-items: center;
	gap: 5px;
}
.tbl-btn {
	cursor: pointer;
	color: #000;
	padding: 5px 10px;
	margin: 5px 5px 0 0;
	border-radius: 5px;
	font-size: 10px;
	display: inline-block;
	border: 1px solid var(--greymid);
}
.layout-item {
	cursor: pointer;
	padding: 5px;
	text-align: left;
	border-bottom: 1px solid #CCC;
}
</style>