<template>
	<div>
		<TopTabBar>
			<template v-slot:belowtabs>
				<div class="flex">
					<div class="shortcut-group">
						<p class="shortcut-name">Clear</p>
						<div class="shortcuts">
							<span class="freq-shortcut" @click="clear">Clear</span>
						</div>
					</div>
					<div class="shortcut-group">
						<p class="shortcut-name">State</p>
						<div class="shortcuts">
							<span class="freq-shortcut" @click="shortcutMarkets('state', 'all')">All</span>
							<span class="freq-shortcut" @click="shortcutMarkets('state', 'NSW')">NSW</span>
							<span class="freq-shortcut" @click="shortcutMarkets('state', 'VIC')">VIC</span>
							<span class="freq-shortcut" @click="shortcutMarkets('state', 'QLD')">QLD</span>
							<span class="freq-shortcut" @click="shortcutMarkets('state', 'SA')">SA</span>
							<span class="freq-shortcut" @click="shortcutMarkets('state', 'WA')">WA</span>
							<span class="freq-shortcut" @click="shortcutMarkets('state', 'TAS')">TAS</span>
						</div>
					</div>
					<div class="shortcut-group">
						<p class="shortcut-name">TV Agg Mkts</p>
						<div class="shortcuts">
							<span class="freq-shortcut" @click="shortcutMarkets('tvagg', 11)">QLD</span>
							<span class="freq-shortcut" @click="shortcutMarkets('tvagg', 9)">Nth NSW</span>
							<span class="freq-shortcut" @click="shortcutMarkets('tvagg', 12)">Sth NSW</span>
							<span class="freq-shortcut" @click="shortcutMarkets('tvagg', 15)">Vic</span>
						</div>
					</div>
					<div class="shortcut-group">
						<p class="shortcut-name">Owner</p>
						<div class="shortcuts">
							<span class="freq-shortcut" @click="shortcutMarkets('owner', 'sca')">SCA</span>
							<span class="freq-shortcut" @click="shortcutMarkets('owner', 'arn')">ARN</span>
							<span class="freq-shortcut" @click="shortcutMarkets('owner', 'ace')">Ace</span>
						</div>
					</div>
					<div class="shortcut-group">
						<p class="shortcut-name">Rep Company</p>
						<div class="shortcuts">
							<span class="freq-shortcut" @click="shortcutMarkets('rep', 'sca')">SCA</span>
							<span class="freq-shortcut" @click="shortcutMarkets('rep', 'arn')">ARN</span>
						</div>
					</div>
					<div class="shortcut-group">
						<p class="shortcut-name">Group</p>
						<div class="shortcuts">
							<span class="freq-shortcut" @click="shortcutMarkets('group', 'smbap')">SMBAP</span>
							<span class="freq-shortcut" @click="shortcutMarkets('group', 'gfk')">GFK</span>
							<span class="freq-shortcut" @click="shortcutMarkets('group', 'xtra')">Xtra</span>
						</div>
					</div>
				</div>
			</template>
		</TopTabBar>
		<div class="body-hold">
			<div class="view-holder">
				<div v-if="reportType === 'reachFrequency'" style="margin-bottom: 20px;">
					<NotificationBox>
						<p>If you have a spot schedule in an approved CSV or Excel file, you can jump straight to
							<span class="text-link" @click="store.activeTab = 'Schedule'">the Schedule page</span> to upload it.
						</p>
					</NotificationBox>
				</div>
				<p class="subheading">{{reportTitle}}</p>
				<div class="filterbox-hold">
					<select v-model="store.marketViewListChooser" class="selectbox">
						<option value="all">Show All Markets</option>
						<option value="surveyed">Show Surveyed Markets</option>
						<option v-if="reportType === 'reachFrequency'" value="surveyedrf">Show R&F Available Markets</option>
						<option value="fullaccess">Show Markets With Data Access</option>
					</select>
					<input type="text" placeholder="Filter market by name..." v-model="filterText" class="filterbox" />
					<div>
						<input type="checkbox" v-model="showGFKMarkets" id="showGFKMarkets" style="margin-left:10px;" />
						<label for="showGFKMarkets">GFK</label>
						<input type="checkbox" v-model="showXtraMarkets" id="showXtraMarkets" style="margin-left:10px;" />
						<label for="showXtraMarkets">Xtra</label>
					</div>
				</div>
				<div class="checkbox-list-hold" style="margin-top: 20px;">
					<MarketsTable :filtertext="filterText" :inrows="filteredMarkets" v-on:showsum="showMarketSummary($event)"></MarketsTable>
				</div>

				<div v-if="showSummaryView" class="loader-overlay">
					<div class="market-summary-view">
						<MarketSummary :mktob="summaryMarketOb" v-on:close="showSummaryView = false"></MarketSummary>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MarketSummary from "@/components/views/MarketSummary";
import MarketsTable from "@/components/MarketsTable";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import TopTabBar from "@/components/TopTabBar.vue";
import NotificationBox from "@/components/NotificationBox.vue";
export default {
	name: "MarketsView",
	components: {
		NotificationBox,
		TopTabBar,
		MarketsTable,
		MarketSummary,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',

			fields: [
				'selected', //virtual column
				{key: 'name', label: 'Market Name', sortable: true},
				{key: 'state', label: 'State', sortable: true},
				{key: 'indpop', label: '10+ Pop', sortable: true, thClass: 'text-right'},
				'ownership', //virtual column
				'representation', //virtual column
				{key: 'tvAggId', label: 'TV Agg Mkt', sortable: true},
				{key: 'surveyed', label: 'Surveyed', sortable: true},
				// {key: 'craPDFurl', label: 'CRA PDF', sortable: false},
			],
			rowClass: 'trow',

			summaryMarketOb: null,
			showSummaryView: false,
			showGFKMarkets: true,
			showXtraMarkets: true,
		}
	},
	computed: {
		listviewchooser() {
			return this.store.marketViewListChooser;
		},
		reportType() {
			return this.store.reportType;
		},
		reportTitle() {
			if(this.reportType === 'reachFrequency') return "R&F Report";
			else if(this.reportType === 'multiRank') return 'Multi-Station Rank Report (choose one market)'
			else if(this.reportType === 'duplicationVenn') return 'Audience Duplication Venn Diagram (choose one market)'
			else if(this.reportType === 'optimiserReport') return 'Schedule Optimiser'
			//metro only gfk
			else if(this.reportType === 'ebbAndFlow') return 'Ebb & Flow Report (choose one market)'
			else if(this.reportType === 'duplication') return 'Audience Duplication Report (choose one market)'
			else if(this.reportType === 'loyalty') return 'Station Loyalty Report (choose one market)'
			else if(this.reportType === 'stationP1') return 'Station P1/P2 Report (choose one market)'
			//regional
			else if(this.reportType === 'tslCrosstabs') return 'TSL Crosstabs Report (choose one market)'
			else if(this.reportType === 'pctStnCumeByDaypart') return 'Percent Cume By Daypart Report (choose one market)'
			else if(this.reportType === 'audSharingCumeReg') return 'Audience Sharing Cume Report (choose one market)'
			else if(this.reportType === 'audSharingMostReg') return 'Audience Sharing Station Most Report (choose one market)'
			else if(this.reportType === 'regFlowFromBreakfast') return 'Flow From Breakfast Through Dayparts Report (choose one market)'
			return "Standard Report";
		},
		filteredMarkets() {
			let temp = this.store.markets.filter(item => item.postcodesOnly === false)
			if(this.listviewchooser === 'surveyed') temp = temp.filter(item => item.surveyed === true);
			else if(this.listviewchooser === 'fullaccess') temp = temp.filter(item => item.explicitFullAccess === 1);
			else if(this.listviewchooser === 'surveyedrf') {
				temp = []
				let surveyedTemp = this.store.markets.filter(item => item.surveyed === true && item.postcodesOnly === false)
				for(let st of surveyedTemp) {
					if(st.isGfk === true) temp.push(st)
					else {
						let su = this.store.surveys.find(item => item.survey_code === st.latestSurveyId)
						if (su && su.quota >= 600) temp.push(st)
					}
				}
			}
			if(this.showGFKMarkets === true && this.showXtraMarkets === false) temp = temp.filter(item => item.isGfk === true)
			else if(this.showGFKMarkets === false && this.showXtraMarkets === true) temp = temp.filter(item => item.isGfk === false)
			if(this.filterText.length > 0) { //either already selected or matching text
				return temp.filter(item => this.store.selectionObs.markets.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return temp;
		},
	},
	methods: {
		showMarketSummary(mktob) {
			this.summaryMarketOb = mktob;
			this.showSummaryView = true;
		},
		clear() {
			this.store.selectionObs.markets = [];
		},
		shortcutMarkets(type, statename) {
			if(type === 'state') {
				if(statename === 'all') {
					this.store.selectionObs.markets = this.filteredMarkets;
				}
				else {
					this.store.selectionObs.markets = this.filteredMarkets.filter(item => item.state === statename);
				}
			}
			else if(type === 'tvagg') {
				this.store.selectionObs.markets = this.filteredMarkets.filter(item => item.tvAggId === statename);
			}
			else if(type === 'owner') {
				this.store.selectionObs.markets = this.filteredMarkets.filter(item => item.ownership.owns.includes(statename));
			}
			else if(type === 'rep') {
				this.store.selectionObs.markets = this.filteredMarkets.filter(item => item.ownership.reps.includes(statename));
			}
			else if(type === 'group') {
				if(statename === 'smbap') {
					this.store.selectionObs.markets = this.filteredMarkets.filter(item => this.store.gfkMetroMktIds.includes(item.id))
				}
				else if(statename === 'gfk') {
					this.store.selectionObs.markets = this.filteredMarkets.filter(item => item.isGfk === true)
				}
				else if(statename === 'xtra') {
					this.store.selectionObs.markets = this.filteredMarkets.filter(item => item.isGfk === false)
				}
			}
		},
	},
	watch: {

	},
	mounted() {
		let gfkOnlyReports = ['loyalty','ebbAndFlow','duplication','stationP1']
		let xtraOnlyReports = ['tslCrosstabs','pctStnCumeByDaypart','audSharingCumeReg','audSharingMostReg','regFlowFromBreakfast']
		if(gfkOnlyReports.includes(this.store.reportType)) {
			this.store.selectionObs.stations = this.store.selectionObs.stations.filter(item => item.isGfk === true)
			this.showXtraMarkets = false
		}
		else if(xtraOnlyReports.includes(this.store.reportType)) {
			this.store.selectionObs.stations = this.store.selectionObs.stations.filter(item => item.isGfk === false)
			this.showGFKMarkets = false
		}
		if(this.reportType === 'reachFrequency') {
			this.store.marketViewListChooser = 'surveyedrf'
		}
		else this.store.marketViewListChooser = 'surveyed'
	}
}
</script>
<style>
table.market-table tr {
	transition: background-color 0.2s ease-in-out;
}
table.market-table tbody tr:hover {
	background-color: var(--greylight);
}
table.market-table td {
	vertical-align: middle;
	padding: 2px 15px;
}
th.text-right {
	text-align: right !important;
}
</style>
<style scoped>
.view-holder {
	margin: 0 20px;
}
.filterbox-hold {
	display: flex;
	align-items: center;
}
.market-table {
	max-width: 1200px;
}
.market-summary-view {
	width: 90%;
	height: 90%;
	position: fixed;
	top: 5%;
	left: 5%;
}
.mktlabel {
	cursor: pointer;
}
.mktlabel:hover {
	text-decoration: underline;
}
</style>